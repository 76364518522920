<template>
    <div class="small" style="text-align: left; padding: 30px 30px 30px 60px; color: red; position: fixed; bottom: 0; width: 100%; background-color: rgba(245, 179, 221, 0.7);">
        <h5>Important: </h5>
        <ul>
            <li>This game uses Metamask wallet. Please have Metamask wallet browser extension installed and enabled</li>
            <li>
                Metamask installation and setup instructions can be referred from 
                <a href="https://metamask.io/download/" target="_blank">
                    here
                </a>
            </li>
            <li>This game is built and tested on Megumii Chan. Please connect to Megumii Chan using Metamask Wallet to play the game</li>
            <li>
                Use information from <a href="https://k.megumii.xyz/chain" target="_blank">This Page</a> to add Megumii Chan to your Metamask Wallet
            </li>
            <li>Paying game fee might take more time depending on the network congestion. Please try to refresh page after few minutes if there is no response within 2 minutes of submission</li>
        </ul>
   </div>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>